import styled, { createGlobalStyle, css, keyframes } from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';
import { IShowDetails } from './stickyOffer.model';

export const progressbarHeight = 6;

const OpaqueActive = css`
  width: 100vw;
  height: 100vh;
  animation: fade-in 0.9s ease-in forwards;
  background-color: rgba(0, 0, 0, 50%);

  // z-index: 20;

  @keyframes fade-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

export const OpaqueBackground = styled.div<{ isActive: IShowDetails }>`
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  ${(p) => (p.isActive == 'slide-up' ? OpaqueActive : '')}
`;

export const MainContainer = styled.div<{ activationBoxHeight: number; isApp?: boolean }>`
  z-index: 4;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: ${(p) => (p.isApp ? '80vh' : '100%')};
  transform: translate3d(0, calc(100% - ${(props) => `${props.activationBoxHeight}px`}), 0);
  background-color: ${colors.$ffffff};
  border-radius: ${pxToRem(6, 6, 0, 0)};
  overflow: hidden;

  &.slide-up {
    @keyframes slide-out-up {
      from {
        transform: translate3d(0, calc(100% - ${(props) => `${props.activationBoxHeight}px`}), 0);
      }

      to {
        transform: translate3d(0, 0, 0);
      }
    }

    animation-name: slide-out-up;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
  }

  &.slide-down {
    @keyframes slide-out-down {
      from {
        transform: translate3d(0, 0, 0);
      }

      to {
        transform: translate3d(0, calc(100% - ${(props) => `${props.activationBoxHeight}px`}), 0);
      }
    }

    animation-name: slide-out-down;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
  }

  &.upward {
    animation: increase-up 0.4s forwards ease-out;
  }

  &.downward {
    animation: decrease 0.4s forwards ease-out;
  }

  @keyframes increase-up {
    from {
      bottom: -140px;
    }

    to {
      bottom: 0;
    }
  }

  @keyframes decrease {
    from {
      bottom: 0;
    }

    to {
      bottom: -140px;
    }
  }
`;

export const OverlayGlobalStyle = createGlobalStyle`
  body {
  overflow: hidden;
  }
`;

export const ActivationBox = styled.div<{ withProgressbar: boolean | undefined }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 18px 32px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 20px 80px;
  }

  border-top: ${(props) => (props.withProgressbar ? `unset` : `0.5px solid ${colors.$bebebe}`)};
  border-bottom: 0.5px solid ${colors.$bebebe};
`;

export const CtaContainer = styled.div`
  min-width: 123px;
  max-width: 65%;

  button,
  a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: unset;
    min-width: ${pxToRem(97)};

    @media (min-width: ${breakpoints.tablet}) {
      min-width: ${pxToRem(209)};
    }

    @media (min-width: ${breakpoints.desktop}) {
      min-width: ${pxToRem(220)};
    }
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1); /* Initial size */
  }
  50% {
    transform: scale(1.3); /* Bigger */
  }
  75% {
    transform: scale(1.3); /* Hold at bigger size */
  }
  100% {
    transform: scale(1); /* Back to original size */
  }
`;

export const Details = styled.div<{ isApp?: boolean; isPulsing?: boolean }>`
  display: block;
  color: ${colors.$262626};
  font-family: ${fonts.regular};
  font-weight: 400;
  ${pxToCssFont(14, 20)}
  >div >p {
    transform-origin: left;

    ${({ isPulsing }) =>
      isPulsing &&
      css`
        animation: ${pulse} 2s linear;
      `}
  }

  button {
    min-width: ${pxToRem(97)};

    @media (min-width: ${breakpoints.tablet}) {
      min-width: ${pxToRem(209)};
    }

    @media (min-width: ${breakpoints.desktop}) {
      min-width: ${pxToRem(220)};
    }
  }

  p {
    padding: 0;
    margin: 0;
    font-family: ${({ isApp }) => (isApp ? 'inherit' : fonts.exbold)};
    font-weight: ${({ isApp }) => (isApp ? 700 : 400)};
    ${pxToCssFont(24, 32)}

    @media (min-width: ${breakpoints.desktop}) {
      ${pxToCssFont(36, 48)}
    }

    .rec {
      ${pxToCssFont(18, 24)}

      @media (min-width: ${breakpoints.tablet}) {
        ${pxToCssFont(24, 32)}
      }

      @media (min-width: ${breakpoints.desktop}) {
        ${pxToCssFont(28, 32)}
      }
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .button-slide-container {
      margin-right: 16px;
    }

  }
`;

export const DetailsLoading = styled.div`
  width: 160px;
  height: 50px;
  background: linear-gradient(0.25turn, transparent, ${colors.$ffffff}, transparent),
    linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  animation: loading 1.5s infinite;

  @media (min-width: ${breakpoints.tablet}) {
    width: 250px;
  }

  @keyframes loading {
    to {
      background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
  }
`;

export const TopLabel = styled.span`
  p {
    ${pxToCssFont(12, 16)};
  }
  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(16, 22)}
  }
`;

export const ShowDetailsCta = styled.button<{ rotation: '-90' | '90' }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  min-width: inherit !important;

  p {
    margin: 0;
    font-family: ${fonts.regular};
    font-weight: 400;
    ${pxToCssFont(14, 18)}
    color: ${colors.$262626};
    text-transform: uppercase;
  }

  svg {
    margin-left: 4px;
    width: 4px;
    height: 8px;
    transform: rotate(${(props) => (props.rotation ? props.rotation : '0')}deg);
  }
`;

// element a style moved from MainContainer to ChildrenContainer
export const ChildrenContainer = styled.div<{
  activationBoxHeight: number;
  ctaContainerWidth: number;
}>`
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  overflow-y: auto;
  height: calc(100% - ${(props) => props.activationBoxHeight + 40}px);
  padding: 0 24px;

  @media (min-width: ${breakpoints.desktop}) {
    max-width: calc(100% - ${(props) => props.ctaContainerWidth}px);
    margin: 0 auto;
  }

  a {
    color: ${colors.$262626};
  }
`;

export const ProgressbarContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${colors.$bebebe};
  width: 100%;
  padding: 0;
`;

export const Bar = styled.div<{ stepCoverage: number }>`
  min-height: ${progressbarHeight}px;
  width: ${(props) => props.stepCoverage}%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: ${colors.$e60000};
  transition: width 1s ease;
`;
