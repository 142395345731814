export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'mva:primary'
  | 'mva:secondary'
  | 'mva:alt1';

export const SECONDS_TIMING_ANIMATION = 1.2;

export enum ICardDirectionType {
  NONE,
  PREV,
  NEXT,
}

export enum ICardStyleType {
  HIDDEN,
  FIRST,
  SECOND,
  THIRD,
}

export interface IMainCardStyle {
  HIDDEN: ICardStyle;
  FIRST: ICardStyle;
  SECOND: ICardStyle;
  THIRD: ICardStyle;
}

export interface IMainCardsStyle {
  MOBILE: IMainCardStyle;
  TABLET: IMainCardStyle;
  DESKTOP: IMainCardStyle;
}

export type INextStep = Pick<IStepCard, 'component'>;

export interface ICardStyle {
  top: number;
  width: number;
  height: number;
  opacity?: number;
  zIndex?: number;
}

export type IhandleOnChangeEnableGoNext = (enableGoNext?: boolean, nextStep?: INextStep) => void;

export interface ICommonData {
  handleGoNextSlide?: (
    nextStep?: INextStep,
    forceFinish?: boolean,
    handleOnFinish?: () => void
  ) => void;
  handleGoPrevSlide?: () => void;
  handleOnFinish?: () => void;
  handleOnChangeEnableGoNext?: IhandleOnChangeEnableGoNext;
  forceFinish?: boolean;
  handleOnClose?: () => void;
  steps?: IStepCard[];
}

export interface IStepCard {
  title?: string;
  enableGoNext?: boolean;
  component?: ({ handleGoNextSlide, handleGoPrevSlide }: ICommonData) => JSX.Element | null;
  cardStyle?: ICardStyle;
  cardStyleType?: ICardStyleType;
}

export interface IStepperCardsProps {
  steps: IStepCard[];
  enableStickyStepper?: boolean;
  titleStickyStepper?: string;
  topLabelStickyStepper?: string;
  actionLabelStickyStepper?: { openProductDetails: string; closeProductDetails: string };
  nextLabelStickyStepper?: string;
  variantNextLabelStickyStepper?: ButtonVariant;
  contentStickyStepper?: JSX.Element;
  onBack?: () => void;
  onClose?: () => void;
  onFinish?: () => void;
  forceFinish?: boolean;
  blurredImage?: string;
  disableBack?: boolean;
  hideBackButton?: boolean;
  visibilityModal?: boolean;
  isLoadingContainer?: boolean;
  isLoadingStickyOffer?: boolean;
  isLoadingGoNext?: boolean;
  isForcedDisableCtaActivation?: boolean;
  lottieLoader?: string;
  hideInternalComponents?: boolean;
  onActiveSlideChange?: (activeSlide: number) => void;
  goBackLabel?: string;
}

export interface IStepperUtils {
  _stepperGoNext: (
    nextStep?: INextStep,
    forceFinish?: boolean,
    handleOnFinish?: () => void
  ) => void;
  _stepperGoPrev: () => void;
  _getActiveSlideIndex: () => number;
  _setActiveSlideIndex: (activeIndex: number) => void;
}

export interface IBackStyle {
  [key: string]: string;
}
